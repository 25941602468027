import _ from 'lodash';
import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap';
import Select from 'react-select';
import { symptoReq } from '../utils/auth/utils';
import { generateAuthHeader, type BearerT } from '../serviceIntros/utils';

const DEFAULT_STATUSES = [
  'New Patient Message',
  'New Provider Message',
  'Patient Not Consented',
];

const StatusIgnore = ({
  authData,
  setStatuses,
  statuses,
}: {
  authData: null | BearerT
  setStatuses: (statuses: string[]) => void,
  statuses: string[],
}) => {
  const [statusNames, setStatusNames] = useState<{
    value: string,
    label: string,
  }[]>([]);
  useEffect(() => {
    const fetchStatuses = async () => {
      const data = (await symptoReq(
        `/providers/clinics/status`,
        {
          method: 'GET',
        },
        generateAuthHeader(authData),
      ) as {
        statusName: string,
        statusId: string,
        genericSurvey: null | {
          id: string,
          name: string,
        },
      }[]);
      setStatusNames(_.uniq([
        ...data.map(({ statusName }) => (statusName)),
        ...DEFAULT_STATUSES,
      ]).map((statusName) => ({
        value: statusName, label: statusName,
      })));
    };
    if (authData == null) {
      return;
    }
    void fetchStatuses();
  }, [authData]);

  return (
    <Card className="bg-light w-50 mx-auto d-block pb-3 mt-3 px-5">
      <div className="text-primary text-large text-center mb-1 mt-3">Select Statuses to Ignore</div>
      <div className="text-small mb-3 text-center text-secondary">
        Patients with these statuses will be ignored when backfilling messages.
      </div>
      <Select
        className="text-small"
        classNamePrefix="TagList"
        placeholder="Search for Tag"
        isMulti
        options={statusNames}
        isLoading={statusNames == null}
        value={statuses.map((status) => ({
          value: status,
          label: status,
        }))}
        onChange={async (data) => {
          setStatuses(data.map(({ value }) => (value)));
        }}
      />
    </Card>
  );
};

export default StatusIgnore;

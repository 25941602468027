import pLimit from 'p-limit';
import _ from 'lodash';
import { BearerT, symptoReq, generateAuthHeader } from '../utils/auth/utils';
import { fixCampaignForPatient } from './campaignEnrollmentUtils';

export interface CampaignT {
  campaignId: string;
  allowMultipleEnrollment: boolean;
  autoEnrollment: any;
  autoRemoval: any;
  campaignItems: any[];
  deprecated: boolean;
  description: string;
  name: string;
  showOnPatientCreation: boolean;
  patientIds?: string[];
}

const getPatientsFromCampaign = async ({
  authData,
  campaignId,
}: {
  authData: BearerT;
  campaignId: string;
}): Promise<Array<string>> => {
  const enrolledPatientIds  = await symptoReq(
    `/providers/campaigns/${campaignId}/patients`,
    {
      method: 'GET',
    },
    generateAuthHeader(authData)
  );
  return _.uniq(enrolledPatientIds);
};

export const getCampaigns = async ({
  authData,
  onProgress,
}: {
  authData: BearerT;
  onProgress: (numProcessed: number, numCampaigns: number) => void
}): Promise<{
  deprecatedCampaigns: Array<CampaignT & {
    patientIds: Array<string>;
  }>;
  liveCampaigns: Array<CampaignT>;
}> => {
  if (authData == null) {
    return {
      deprecatedCampaigns: [],
      liveCampaigns: [],
    }
  }

  const allCampaigns = await symptoReq(
    '/providers/campaigns?includeDeprecated=true',
    {
      method: 'GET',
    },
    generateAuthHeader(authData)
  );


  const deprecatedCampaigns = allCampaigns.filter(
    ({ deprecated }) => deprecated === true
  );

  let numProcessed = 0;
  const hydratedDeprecatedCampaigns = await Promise.all(
    deprecatedCampaigns.map(async (campaign: CampaignT) => {
      const enrolledPatientIds  = await getPatientsFromCampaign({
        authData,
        campaignId: campaign.campaignId,
      });
      numProcessed = numProcessed + 1;
      onProgress(numProcessed, deprecatedCampaigns.length)
      return {
        ...campaign,
        patientIds: enrolledPatientIds,
      };
    }));

  return {
    deprecatedCampaigns: hydratedDeprecatedCampaigns,
    liveCampaigns: allCampaigns.filter(({ deprecated }) => deprecated === false),
  };
};

export const bulkFixCampaigns = async ({
  authData,
  existingPatientCampaignId,
  patientTvIds,
  newPatientCampaignId,
  onProgress,
}: {
  authData: BearerT;
  existingPatientCampaignId: string;
  patientTvIds: string[];
  newPatientCampaignId: null | string;
  onProgress: () => void,
}) => {
  const limit = pLimit(10);
  await Promise.all(patientTvIds.map(async (patientTvId) => limit(async () => {
    await fixCampaignForPatient({
      authData,
      existingPatientCampaignId,
      patientTvId,
      newPatientCampaignId,
    });
    onProgress();
  })));
};

import { symptoReq, BearerT } from '../utils/auth/utils';

export const generateAuthHeader = (auth: BearerT, includeRateToken: boolean = false) => ({
  'Authorization': 'Bearer '+auth.authCode,
  ...(includeRateToken ? {
    'rate-token': auth.rateToken,
  } : {}),
});

const assignNewInstrument = async ({
  authCode,
  patientTvId,
  genericSurveyId,
}: {
  authCode: BearerT,
  patientTvId: string,
  genericSurveyId: string,
}) => {
  const { patientSurveyId } = await symptoReq(`/providers/surveys/${genericSurveyId}/default/${patientTvId}/send/now`, {
    body: JSON.stringify({
    }),
    method: 'POST',
  }, generateAuthHeader(authCode));
  return { patientSurveyId };
}
export const assignNewSurvey = async ({
  authCode,
  patientTvId,
  genericSurveyId,
}: {
  authCode: BearerT
  patientTvId: string;
  genericSurveyId: string;
}): Promise<{ patientSurveyId: string }> => {
  return assignNewInstrument({
    authCode,
    patientTvId,
    genericSurveyId,
  })
};

export const fillEncounterData = async ({
  authCode,
  patientSurveyId,
  encounterData,
  patientTvId,
}: {
  authCode: BearerT;
  encounterData: Array<{
    questionTitle: string;
    value: string;
  }>;
  patientSurveyId: string;
  patientTvId: string;
}): Promise<{ didFill: boolean }> => {
  try {
    console.log(`Filling encounter data for ${patientSurveyId} - ${JSON.stringify(encounterData)}}`)
    await symptoReq(`/providers/${patientSurveyId}/encounters/response`, {
      body: JSON.stringify(encounterData),
      method: 'POST',
    }, generateAuthHeader(authCode));
    return { didFill: true };
  } catch (e) {
    alert(e);
    alert(JSON.stringify(encounterData))
    await symptoReq(`/providers/patientSurvey/${patientSurveyId}/tags`, {
      "body": JSON.stringify({
        patientTvId,
        updatedTags: ['Invalid / Accidental Encounter'],
      }),
      "method": "POST",
    }, generateAuthHeader(authCode));
    alert('Encounter marked as invalid');
    return { didFill: false };
  }
};

export const sendMessageToPatient = async (message: string, patientTvId: string, authCode: BearerT) => {
  await symptoReq(`/providers/patients/${patientTvId}/genericsurvey`, {
    body: JSON.stringify({
      survey: {
        duration: 24,
        defaultTags: null,
        messageData: {
          creation: {
            text: message,
            email: null,
          },
          reminder: {
            text: null,
            email: null,
          },
        },
        notifications: {
          daysBeforeDueDate: [],
        },
        startDatePreference: {
          type: 'immediate',
        },
        genericSurveyId: '71086616-b064-4990-87c8-3955cdad6040',
      }
    }),
    method: 'POST',
  }, generateAuthHeader(authCode));
}

import _ from 'lodash';
import { symptoReq } from '../utils/auth/utils';
import { generateAuthHeader, type BearerT } from './utils';

type SimplifiedResponseT = ({
  type: 'slider' | 'input',
  output: string | null,
  title: string,
  id: string,
  tags: string,
} | {
  type: 'multiselect',
  output: Array<string>,
  title: string,
  id: string,
  tags: string,
})[];

const getServiceIntroductionOptions = async ({
  patientTvId,
  response,
}: {
  patientTvId: string
  response: SimplifiedResponseT,
}): Promise<{
  servicesRequest: null | string
}> => {
  const careServicesRequestedQuestion = response.find(({ title }) => (
    title.includes('services are you interested in?')
  ));
  if (careServicesRequestedQuestion == null || careServicesRequestedQuestion.type !== 'multiselect') {
    throw new Error(`${patientTvId}: no valid care services requested question in service introduction`);
  }
  const specialistFollowup = response.find(({ title }) => (
    title.includes('Specialist Follow-up')
  ));
  const optionsSelected: string[] = careServicesRequestedQuestion.output.map((option) => option.trim());
  const specialistOutput = (specialistFollowup != null && specialistFollowup.type === 'input') ? specialistFollowup.output : null;


  return optionsSelected.length > 0 || specialistOutput != null
    ? ({
        servicesRequest: `requested assistance with the following items: ${optionsSelected.join(', ')}. ${specialistOutput != null ? `Specialist follow-up: ${specialistOutput}` : ''}`
      })
    : { servicesRequest: null };
};

const getCaregiverInfo = async ({
  response,
}: {
  response: SimplifiedResponseT,
}): Promise<{
  careGiverString: null | string
}> => {
  const parentCaregiverInfo = response.find(({ title }) => (
    title.includes('Parent / Caregiver Info')
  ));
  const relationshipType = response.find(({ title }) => (
    title.includes('Relationship to Parent')
  ));
  if (parentCaregiverInfo == null || parentCaregiverInfo.type !== 'input') {
    return {
      careGiverString: null,
    };
  }
  const relationshipTypeText = relationshipType != null && relationshipType.type === 'input'
    ? relationshipType.output
    : null;
  return {
    careGiverString: `${parentCaregiverInfo.output} (${relationshipTypeText ?? ''})`,
  };
};

const getConsentStatus = async ({
  patientTvId,
  response,
}: {
  patientTvId: string
  response: SimplifiedResponseT,
}): Promise<{
  status: 'consent' | 'decline' | 'empty'
}> => {
  const consentQuestion = response.filter(({ title }) => (
    title.includes('We need your consent to our navigation services')
  ));
  if (consentQuestion == null) {
    throw new Error(`${patientTvId}: no valid consent question / response in open service introductions`);
  }
  if (consentQuestion.length > 1) {
    throw new Error(`${patientTvId}: multiple consent questions / responses in open service introductions`);
  }
  const targetConsentQuestion = _.head(consentQuestion);

  if (targetConsentQuestion.output == null) {
    return { status: 'empty' };
  }
  if (targetConsentQuestion.type !== 'slider') {
    throw new Error(`${patientTvId}: ${targetConsentQuestion.type} not valid consent question type in open service introductions`);
  }

  return {
    status: targetConsentQuestion.output.includes('I CONSENT To Navigation Services at Ascension Health') ? 'consent' : 'decline',
  };
};

export const getServiceIntroductionResponses = async ({
  patientSurveyId,
  authCode,
  patientTvId,
}: {
  patientSurveyId: string,
  authCode: BearerT,
  patientTvId: string,
}): Promise<{
  status: 'ok',
  data: {
    didConsent: 'decline' | 'consent' | 'empty'
    servicesRequestedMessage: string | null
    careGiverMessage: string
  },
} | {
  status: 'failure',
  message: string,
}> => {
  try {
    const response = await symptoReq(`/providers/patientSurvey/${patientSurveyId}/minimal`, {
      method: 'GET'
    }, generateAuthHeader(authCode)) as null | SimplifiedResponseT;
    if (response == null) {
      throw new Error(`${patientTvId}: no valid response to service introduction`);
    }
    const {
      status: consentStatus
    } = await getConsentStatus({
      response,
      patientTvId,
    });
    const {
      servicesRequest,
    } = await getServiceIntroductionOptions({
      response,
      patientTvId,
    });
    const { careGiverString } = await getCaregiverInfo({
      response,
    });
    return {
      status: 'ok',
      data: {
        didConsent: consentStatus,
        servicesRequestedMessage: servicesRequest,
        careGiverMessage: careGiverString ?? '',
      },
    };
  } catch (e) {
    return {
      status: 'failure',
      message: e.message,
    };
  }
};

export type GPTChatModel = 'gpt-3.5-turbo' | 'gpt-4-turbo' | 'gpt-4o';
export type ChatModel =
  | GPTChatModel
  | 'google-1.5-flash'
  | 'google-1.5-pro'
  | 'claude-instant-1'
  | 'claude-2'
  | 'claude-2.1'
  | 'claude-3-opus'
  | 'claude-3-sonnet'
  | 'claude-3-haiku'
  | 'claude-3.5-sonnet'
  | 'mistral-small-fireworks'
  | 'mistral-medium-fireworks'
  | 'llama-v3-70b-instruct'
  | 'llama-v3.1-405b-instruct';

const modelList: ChatModel[] = [
  'gpt-3.5-turbo',
  'gpt-4-turbo',
  'gpt-4o',
  'google-1.5-flash',
  'google-1.5-pro',
  'claude-instant-1',
  'claude-2',
  'claude-2.1',
  'claude-3-opus',
  'claude-3-sonnet',
  'claude-3-haiku',
  'claude-3.5-sonnet',
  'mistral-small-fireworks',
  'mistral-medium-fireworks',
  'llama-v3-70b-instruct',
  'llama-v3.1-405b-instruct',
]

export default modelList;

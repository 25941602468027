import React, { useState, useEffect } from 'react'
import { Card, Button } from 'react-bootstrap';
import swal from 'sweetalert';
import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';

export type QuestionData = { questionTitle: string, value: string }[];

export const DEFAULT_JSON: QuestionData = [
  {
    questionTitle: 'Followup Message',
    value: 'Hello World',
  },
  {
    questionTitle: 'Outcome',
    value: 'Pending',
  },
];

const JSONEditor = ({
  jsonValue,
  setJsonValue,
}: {
  jsonValue: QuestionData,
  setJsonValue: (data: QuestionData) => void,
}) => {
  const [currentValue, setCurrentValue] = useState<string>(JSON.stringify(jsonValue, null, 2));
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  useEffect(() => {
    setCurrentValue(JSON.stringify(jsonValue, null, 2));
    setHasChanges(false);
  }, [jsonValue]);
  return (
    <Card className="bg-light w-50 mx-auto d-block pb-3 mt-3">
      <div className="text-primary text-large text-center mb-2 mt-3">
        JSON to Update
      </div>
      <div className="text-small px-5 mb-3">
        In the following format:
        <pre>
          {`[{ questionTitle: string, value: string }]`}
        </pre>
        Question Title can be any encounter question title or 'Followup Message' to send a followup message
      </div>
      <CodeMirror
        value={currentValue}
        extensions={[json()]}
        className="border mx-5 mt-3 rounded overflow-hidden"
        placeholder="Please enter JSON."
        onChange={(value) => {
          setCurrentValue(value);
          setHasChanges(true);
        }}
        style={{
          fontSize: 12,
          backgroundColor: '#f5f5f5',
        }}
      />
      {hasChanges && (
        <Button
          className="text-decoration-none font-weight-bold mx-auto d-block text-small my-3 bg-white"
          variant="outline-primary"
          onClick={() => {
            try {
              const parsed = JSON.parse(currentValue);
              if (!Array.isArray(parsed)) {
                swal('Not an array');
                return
              }
              if (!parsed.every((item) => (
                typeof item === 'object' && item != null && typeof item.questionTitle === 'string' && typeof item.value === 'string'
              ))) {
                swal('Not an array of objects with questionTitle and value');
                return;
              }
              setJsonValue(parsed);
            } catch (e) {
              swal('Invalid JSON');
            }
          }}
        >
          Save Updated JSON
        </Button>
      )}
    </Card>
  );
};

export default JSONEditor;

export const parseRace = (race: string | null) => {
  if (race.trim().toLowerCase() === 'white or caucasian') {
    return 'Caucasian or White';
  }
  if (race.trim().toLowerCase() === 'caucasian or white') {
    return 'Caucasian or White';
  }
  if (race.trim().toLowerCase() === 'white') {
    return 'Caucasian or White';
  }
  if (race.trim().toLowerCase() === 'caucasian') {
    return 'Caucasian or White';
  }
  if (race.trim().toLowerCase() === 'refused or declined') {
    return 'Declined';
  }
  if (race.trim() === 'Refused or Declined') {
    return 'Declined';
  }
  return race;
}


export const parseEthnicity = (ethnicitiy: string | null) => {
  if (ethnicitiy.toLowerCase().includes('not')) {
    return 'Not Hispanic / Latinx / Chicanx';
  }
  if (ethnicitiy.toLowerCase().includes('hispanic')) {
    return 'Hispanic / Latinx / Chicanx';
  }
  return 'Unknown';
};

import moment from 'moment';
import { symptoReq } from '../utils/auth/utils';
import { generateAuthHeader, type BearerT } from './utils';

export const fetchPatientSurveys = async ({ patientTvId, authCode }: {
  patientTvId: string,
  authCode: BearerT
}): Promise<{
  type: 'error',
  message: string[],
} | {
  type: 'success',
  data: {
    isSpanish: boolean,
    encounterPatientSurveyId: string
    serviceIntroductionPatientSurveyId: string
  },
}> => {
  const data = (await symptoReq(
    `/providers/patients/${patientTvId}/actionMetadata`,
    {
      method: 'GET',
    },
    generateAuthHeader(authCode),
  ) as {
    alerts: Array<{
      name: string
      startDate: number
      patientSurveyId: string
      type: string
      surveyTags: null | string[]
      previewData: undefined | Record<string, string>
    }>
    pending: Array<{
      name: string
      patientSurveyId: string
      startDate: number
      type: string
      surveyTags: null | string[]
      previewData: undefined | Record<string, string>
    }>
  });
  const allSurveys = [...data.alerts, ...data.pending];

  const errors = [];
  const serviceIntro = allSurveys.filter(({ type, name }) => (
    type === 'instrument' && (
      name.toLowerCase().includes('service intro') ||
      name.toLowerCase().includes('caregiver reconciliation'))
  ));

  if (serviceIntro.length !== 1) {
    errors.push(`${serviceIntro.length} valid open service introductions`);
  }
  if (serviceIntro.length === 0) {
    return {
      type: 'error',
      message: errors,
    }
  }

  const [{ startDate }] = serviceIntro;
  const startDateMoment = moment(startDate);
  const formattedServiceIntroStartDate = moment(startDateMoment).startOf('day').format('MM/DD/YYYY');
  const isSpanish = serviceIntro.some(({ name }) => name.toLowerCase().includes('spanish'));
  const encounterSurvey = allSurveys.filter(({ type, surveyTags, previewData, startDate }) => {
    const encounterStartDate = moment(startDate).startOf('day').format('MM/DD/YYYY');
    return (
      type === 'encounter' &&
      (surveyTags != null ? surveyTags : []).includes('Analytics') &&
      !(surveyTags != null ? surveyTags : []).includes('Invalid / Accidental Encounter') &&
      (previewData == null
        || previewData['Status'] !== 'Complete')
      // if not spanish, make sure start adte the same - spanish is special case
      // since start date might be different
      && (isSpanish || (formattedServiceIntroStartDate === encounterStartDate))
    )
  });
  if (encounterSurvey.length !== 1) {
    errors.push(`${encounterSurvey.length} valid open encounters`);
  }

  const isOldServiceIntro: boolean = startDateMoment.isBefore(moment().subtract(2, 'month'));
  if (isOldServiceIntro) {
    errors.push('Service introduction is older than 2 months');
  }
  if (errors.length > 0) {
    return {
      type: 'error',
      message: errors,
    }
  }
  return {
    type: 'success',
    data: {
      encounterPatientSurveyId: encounterSurvey[0].patientSurveyId,
      serviceIntroductionPatientSurveyId: serviceIntro[0].patientSurveyId,
      isSpanish,
    },
  };
};

import _ from 'lodash';
import React from 'react';
import Select, {
  SingleValue,
  SingleValueProps,
  OptionProps,
  components,
} from 'react-select';
import { ErrorBoundary } from "react-error-boundary";
import {
  CampaignT,
} from './manageCampaigns';
import { BearerT } from '../utils/auth/utils';

export type CampaignWithPatients = (CampaignT & {
  patientIds: string[];
}) | (CampaignT);

const CampaignOptRender = ({
  campaign, className,
}: {
  className?: string;
  campaign: CampaignWithPatients;
  label: string;
}) => (
  <div className={className}>
    <div className="text-dark">{`${campaign.name}`}</div>
    <div className="text-dark text-small">
      {campaign.patientIds && (<div className="font-weight-bold text-warning">{`${campaign.patientIds.length} patients`}</div>)}
      <div>
        {campaign.description}
      </div>
    </div>
  </div>
);

const CampaignOption = (
  props: SingleValueProps<{
    label: string;
    value: string;
    data: CampaignWithPatients;
  }>,
) => (
  <components.SingleValue {...props}>
    <CampaignOptRender
      campaign={props.data.data}
      label={props.data.label}
      className="ps-1 py-2 pe-2"
    />
  </components.SingleValue>
);

const CampaignSelectedOption = (
  props: OptionProps<{
    label: string;
    value: string;
    data: CampaignWithPatients;
  }>,
) => (
  <components.Option {...props}>
    <CampaignOptRender
      campaign={props.data.data}
      label={props.data.label}
    />
  </components.Option>
);


const CampaignDropdown = ({
  campaignData,
  selectedCampaign,
  setSelectedCampaign,
  label,
  authData,
}: {
  campaignData: null | CampaignWithPatients[],
  selectedCampaign: null | CampaignWithPatients,
  setSelectedCampaign: (campaign: CampaignWithPatients) => void,
  label: string;
  authData: BearerT,
}) => (
  <>
    <div className="text-small text-secondary mb-2">
      {label}
    </div>
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Select
        className="text-small"
        placeholder="Search for Campaign"
        isMulti={false}
        components={{
          SingleValue: CampaignOption,
          Option: CampaignSelectedOption,
        }}
        options={_.sortBy((campaignData || [])
          // if no patients, don't show unless patients not even loaded in
          .filter((value) => value.patientIds == null || value.patientIds.length > 0)
          .map((value) => ({
            value: value.campaignId,
            label: value.name,
            data: value,
          })), (value) => value.data.patientIds?.length)}
        value={selectedCampaign != null
          ? {
            value: selectedCampaign.campaignId,
            label: selectedCampaign.name,
            data: selectedCampaign,
          }
          : null}
        onChange={async (
          item: SingleValue<{
            value: string;
            data: CampaignWithPatients;
            label: string;
          }>,
        ) => {
          if (item === null) {
            return;
          }
          setSelectedCampaign(item.data);
        }}
      />
    </ErrorBoundary>
  </>
);

export default CampaignDropdown;

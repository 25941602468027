
import qs from 'qs';

const CLIENT_URL = 'https://apisandbox.symptohealth.com';

export type BearerT = {
  authCode: string, rateToken: string,
};

export const generateAuthHeader = (auth: BearerT) => ({
  'Authorization': 'Bearer '+auth.authCode,
});

export const symptoReq = async (url: string, params, headers = {}, supressSwal?: boolean) => {
	const finalQueryStr =
    params.method === 'GET' && params.query
      ? `?${qs.stringify(params.query as Record<string, unknown>)}`
      : '';

	const resp = await (await fetch(`${CLIENT_URL}${url}${finalQueryStr}`, {
	  "headers": {
	    "accept": "application/json, text/plain, */*",
	    "accept-language": "en-US,en;q=0.9",
			"cache-control": "no-cache",
			"content-type": "application/json;charset=UTF-8",
			"pragma": "no-cache",
			"Referer": "https://app.symptohealth.com/",
			"Referrer-Policy": "strict-origin-when-cross-origin",
			...headers
	  },
	  "referrer": "https://app.symptohealth.com/",
	  "referrerPolicy": "strict-origin-when-cross-origin",
	  ...params
	})).json();
	if (resp.Status !== 'OK') {
		if (supressSwal !== true) {
    	alert(`Failed ${url}. ${JSON.stringify(resp)}`);
		}
		throw new Error(`Failed ${url}. ${JSON.stringify(resp)}`)
	}
	return resp.Response;
};

export const generateAuthCode = async ({
	username,
  password,
}: {
	username: string,
	password: string,
}): Promise<{
	authCode: string, rateToken: string,
}> => {
  console.log("\n\n\nAuthenticating user...");
  try {
    const authCode = await symptoReq(`/authorization`, {
      body: JSON.stringify({
				clientId: username,
      	clientSecret: password,
			}),
			method: 'POST',
    });
    console.log("User authenticated");
		const rateToken = await symptoReq(`/authorization/rateToken`, {
			method: 'GET',
    }, {'Authorization': `Bearer ${authCode}` });
    console.log("Rate token generated");
    return { authCode, rateToken };
  } catch (e) {
    console.log(e);
    alert(`Errored on generating bearer token: ${JSON.stringify(e.response.data)}`);
    throw new Error(JSON.stringify(e.response.data));
  }
}

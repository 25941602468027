import React, { useCallback, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import pLimit from 'p-limit';

import FileSet from '../utils/FileSet';
import AnalyticsInstruction from './AnalyticsInstruction';
import { symptoReq,  generateAuthHeader } from '../utils/auth/utils';

import Auth from '../utils/auth/Auth';

const limit = pLimit(10);

const CombineAnalytics = () => {
  const [auth, setAuth] = useState(null);
  const [encounterData, setEncounterData] = useState(null);
  const [loading, setLoading] = useState<null | {
    progress: number,
    total: number,
  }>(null);
  const backfillEncounter = useCallback(async () => {
    setLoading({
      progress: 0,
      total: encounterData.length,
    });
    await encounterData.map((encounter) => (limit(async ()=> {
      try {
        await symptoReq(`/providers/${encounter.id}/encounters/response`, {
          method: 'POST',
          body: JSON.stringify([]),
        }, generateAuthHeader(auth));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading((prev) => ({
          ...prev,
          progress: prev.progress + 1,
        }));
      }
    })));

  }, [auth, encounterData]);
  return (
    <div>
      <AnalyticsInstruction />
      <Auth
        buttonNode="Login"
        authDone={auth != null}
        onSubmit={async (authData) => {
          setAuth(authData);
        }}
      />
      <Card className="bg-light w-50 mx-auto d-block pb-3 mt-3">
        <div className="text-primary text-large text-center mb-2 mt-3">Kibana Data</div>
        <FileSet
          requiredColumns={["id"]}
          placeholder="Drag 'n Drop or Upload Kibana Export Here"
          updateData={(data) => {
            setEncounterData(data);
          }}
        />
      </Card>
      <Button
        onClick={() => {
          backfillEncounter();
        }}
        className="mx-auto mt-3 d-block text-white"
        disabled={encounterData == null || auth == null || loading != null}
      >
        Reload Encounters
        {
          loading != null && (
            <div className="text-small text-white">
              {loading.progress}/{loading.total}
            </div>
          )
        }
      </Button>
    </div>
  );
}

export default CombineAnalytics;

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import _ from 'lodash';

const phoneUtil = PhoneNumberUtil.getInstance();


const formatNumber = (phoneNumber: string): string => {
  const isLetter =  (c: string) =>  (c.toLowerCase() !== c.toUpperCase());
  const formatNum = (num: string) => (
    num.split('').filter((ch) => (
      !isLetter(ch) && ch !== '*' && ch !== '(' && ch !== ')'
    )).join('')
  );
  const phoneNumberMetadata = phoneUtil.parseAndKeepRawInput(formatNum(phoneNumber).replace(/[^0-9]/g, ''), 'US');
  if (!phoneUtil.isValidNumber(phoneNumberMetadata)) {
    throw new Error('Invalid phone number. Please enter a valid cell phone number');
  }
  const formattedPhoneNumber = phoneUtil.format(phoneNumberMetadata, PhoneNumberFormat.E164);
  return formattedPhoneNumber;
}

export const isValidPhoneNumber = (
	phoneNumber: string,
): boolean => {
	const isInvalidPh = phoneNumber === '999-999-9999'
		|| phoneNumber === '000-000-0000'
    || phoneNumber.replace(/[^0-9]/g, '') === '19999999999'
		//or if only one gdigit again and again
		|| _.uniq(phoneNumber.replace(/[^0-9]/g, '')).length <= 1
    || phoneNumber.trim().length === 0
		|| phoneNumber.endsWith('9999');
  if (isInvalidPh) {
    throw new Error('Invalid phone number. Please enter a valid cell phone number');
  }
  if (formatNumber(phoneNumber) != null) {
    return true;
  }
  throw new Error('Invalid phone number. Please enter a valid cell phone number');
};

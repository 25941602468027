import swal from 'sweetalert';
import _ from 'lodash';
import moment from 'moment';
import { symptoReq,  generateAuthCode } from '../utils/auth/utils';
import { generateAuthHeader, type BearerT } from './utils';
import { fetchPatientSurveys } from './patientSurveys';
import { getServiceIntroductionResponses } from './consentStatus';
import { updateEncounterData } from './updateResponse';

export type PatientResultTypes = 'success' | 'failure' | 'spanish' | 'response already exists' | 'no-assignment';
export type ServiceIntroDemographics = { firstName: string, lastName: string, mrn: string, site: string, navigatorName: string };

export const parseExistingServiceIntros = async (
  auth: { username: string, password: string },
  navAssignments: Array<{
    navigatorName: string, sites: string[], patients: number,
  }>,
  setTotalCount: (number) => void,
  updateData: (patientTvId: string, demos: ServiceIntroDemographics, type: PatientResultTypes, data: string[]) => void,
) => {
  const currentAssignments = _.clone(navAssignments);
  const authCode: BearerT = await generateAuthCode(auth);
  const { totalResults, patients } = (await symptoReq(
    '/providers/patients/cache?statusFilters[]=Caregiver+Reconciliation&statusFilters[]=Patient+Navigation+Needed&limit=1000&offset=0',
    {
      method: 'GET',
    },
    generateAuthHeader(authCode),
  ) as { totalResults: number, patients: Array<{ tvid: string, patientAttributes: { site: string }, firstName: string, lastName: string, mrn: string }> })
  if (totalResults > 1000) {
    swal(
      'More than 1000 patients have un-triaged service introductions. This script can only run 1000 patients at a time. You will have to re-run it again to capture all patients'
    );
  }
  if (totalResults < 1000 && patients.length !== totalResults) {
    throw new Error(`Something fishy is going on....${patients.length} patients but total results is ${totalResults}`);
  }
  setTotalCount(patients.length);
  try {
    await patients.reduce(async (acc, { tvid: patientTvId, patientAttributes: { site }, firstName, lastName, mrn }) => {
      await acc;
      const data = await fetchPatientSurveys({
        patientTvId,
        authCode,
      });
      if (data.type === 'error') {
        updateData(patientTvId, { firstName, navigatorName: 'N/A', lastName, mrn, site }, 'failure', data.message);
      } else if (data.data.isSpanish) {
        updateData(patientTvId, { firstName, navigatorName: 'N/A', lastName, mrn, site }, 'spanish', []);
      } else {
        const serviceIntroResponses = await getServiceIntroductionResponses({
          patientSurveyId: data.data.serviceIntroductionPatientSurveyId,
          patientTvId,
          authCode,
        })
        if (serviceIntroResponses.status === 'failure') {
          updateData(patientTvId, { firstName, navigatorName: 'N/A', lastName, mrn, site }, 'failure', [serviceIntroResponses.message]);
          return;
        }
        const serviceIntroData = [
          serviceIntroResponses.data.didConsent ? 'Consented' : 'Did not consent',
          serviceIntroResponses.data.servicesRequestedMessage,
          serviceIntroResponses.data.careGiverMessage,
        ];
        const targetNav = currentAssignments.find(({ sites, patients }) => sites.includes(site) && patients > 0);
        if (targetNav == null ){
          updateData(patientTvId, { firstName, navigatorName: 'N/A', lastName, mrn, site }, 'no-assignment', [
            ...serviceIntroData,
            'No navigator available for this site',
          ]);
          return;
        }
        targetNav.patients -= 1;
        const status = await updateEncounterData(
          data.data.encounterPatientSurveyId,
          mrn,
          [{
            questionTitle: 'Primary Navigator',
            value: targetNav.navigatorName,
          }, {
            questionTitle: 'Discharged',
            value: 'Yes',
          },  {
            questionTitle: 'Reason for Navigation',
            value: 'Other (Please specify in comments)',
          },  {
            questionTitle: 'In Person PCN Encounter',
            value: 'No',
          }, {
            questionTitle: 'Status',
            value: 'Open',
          }, {
            questionTitle: 'Identification',
            value: 'Digital Communications (Sympto)'
          }, {
            questionTitle: 'Referral Details / Comments',
            value: `${serviceIntroResponses.data.careGiverMessage.trim().length > 0 ? `Caregiver ${serviceIntroResponses.data.careGiverMessage}` : 'Patient'} requested ${serviceIntroResponses.data.servicesRequestedMessage}`,
          }, {
            questionTitle: 'ED Visit / Referral / Assignment Date',
            value: moment().format('MM/DD/YYYY'),
          }, {
            questionTitle: 'Priority Level',
            value: ((serviceIntroResponses.data.servicesRequestedMessage || '').toLowerCase().includes('social assistance') ||
            (serviceIntroResponses.data.servicesRequestedMessage || '').toLowerCase().includes('insurance help'))
              ? '1) Low'
              : '2) Medium',
          }],
          authCode,
        );
        if (status.type === 'error') {
          targetNav.patients += 1;
          updateData(patientTvId, { firstName, navigatorName: 'N/A', lastName, mrn, site }, 'response already exists', ([
            ...serviceIntroData,
            status.message,
          ]));
          return;
        }

        await symptoReq(
          `/providers/patientSurvey/${data.data.serviceIntroductionPatientSurveyId}/complete`,
          {
            method: 'POST',
            body: JSON.stringify({
              patientTvId,
              sendCompletionMessage: false,
            }),
          },
          generateAuthHeader(authCode),
        )

        const metadata = (await symptoReq
          (`/providers/responses/${data.data.serviceIntroductionPatientSurveyId}?patientTvId=${patientTvId}`,
          {
            method: 'GET'
          },
          generateAuthHeader(authCode),) as {
            alert: null | {
              alertId: string,
              active: boolean
            }
          });
        const triageableAlert = metadata.alert != null && metadata.alert.active;
        if (triageableAlert) {
          await symptoReq(`/providers/alerts/${metadata.alert.alertId}`, {
            method: 'PUT',
            body: JSON.stringify({}),
          }, generateAuthHeader(authCode),);
        }

        updateData(patientTvId, { firstName, navigatorName: targetNav.navigatorName, lastName, mrn, site }, 'success', _.compact([
          ...serviceIntroData,
          `Assigned to ${targetNav.navigatorName}`,
          !triageableAlert ? 'Unable to triage alert' : null,
        ]));
      }
    }, Promise.resolve());
  } catch (e) {
    swal('Error', e.message, 'error');
  }
  return currentAssignments;
}

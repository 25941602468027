import { symptoReq } from '../utils/auth/utils';
import { generateAuthHeader, type BearerT } from './utils';

type DataPayloadT = {
  questionTitle: 'Primary Navigator' | 'Discharged' | 'Priority Level' | 'Status' | 'Identification' | 'Reason for Navigation' | 'In Person PCN Encounter' | 'Referral Details / Comments' | 'ED Visit / Referral / Assignment Date'
  value: String
};

export const updateEncounterData = async (
  patientSurveyId: string, mrn: string, updatedData: DataPayloadT[], authCode: BearerT,
): Promise<{ type: 'success' } | {
  type: 'error',
  message: string,
}> => {
  try {
    await symptoReq(
      `/providers/${patientSurveyId}/encounters/response`,
      {
        method: 'POST',
        body: JSON.stringify(updatedData),
      },
      generateAuthHeader(authCode),
      true,
    );
    return { type: 'success' };
  } catch (e) {
    alert(`Failed to update encounter data. ${e.message}. MRN: ${mrn}`);
    throw e;
  }
};

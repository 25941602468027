import React, { useCallback, useState } from 'react';
import swal from 'sweetalert';
import { Card, Button } from 'react-bootstrap';
import { ChatModel } from './modelList';
import styled from 'styled-components';
import { continueLangchainOutput } from './langchain';


const WordWrap = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
  font-size: 0.8rem;
`;

const LangchainRun = ({
  runIndex,
  analyticsOutput,
  prompt,
  updateResponse,
  selectedModel,
  langchainOutput,
  aiAuthToken,
}: {
  analyticsOutput: Record<string, any>;
  langchainOutput: string;
  aiAuthToken: string;
  runIndex: number;
  updateResponse: (response: string) => void;
  selectedModel: ChatModel;
  prompt: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const runAI = useCallback(async () => {
    setLoading(true);

    try {
      // Call LangChain function with the response and Anthropic API key
      const addedOutput = await continueLangchainOutput({
        response: analyticsOutput,
        prompt,
        aiAuthToken,
        model: selectedModel,
        previousOutput: langchainOutput,
      });

      updateResponse(`${langchainOutput}\n${addedOutput}`);

    } catch (e) {
      console.error(e);
      swal("Error", `An error occurred while processing the request. ${e}`, "error");
    } finally {
      setLoading(false);
    }
  }, [analyticsOutput, prompt, aiAuthToken, selectedModel, langchainOutput, updateResponse]);

  return (
    <Card className="mt-3 w-75 mx-auto bg-light">
      <Card.Body>
        <Card.Title>Response {runIndex + 1}:</Card.Title>
        <div className="d-flex flex-row  w-100">
          <div className="overflow-auto w-50">
            <h5>Analytics Output</h5>
            <WordWrap>{JSON.stringify(analyticsOutput, null, 2)}</WordWrap>
          </div>
          <div className="overflow-auto w-50 ps-3">
            <h5>LangChain Output</h5>
            <WordWrap>{langchainOutput}</WordWrap>
            <Button
              onClick={runAI}
              disabled={loading}
            >
              Continue Generation
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LangchainRun;

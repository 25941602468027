import React, { useState, useMemo } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Card, Form } from 'react-bootstrap';
import * as t from "io-ts";
import { PathReporter } from "io-ts/PathReporter";
import { isLeft } from "fp-ts/Either";

import { type PatientResultTypes, type ServiceIntroDemographics, parseExistingServiceIntros } from './collectServiceIntros';

type ResultT = {
  patientTvId: string,
  demographics: ServiceIntroDemographics,
  data: string[],
};

export const NavigatorJSON = (t.array(t.type({
    navigatorName: t.string,
    sites: t.array(t.string),
    patients: t.number,
  }),
));

const DEFAULT_JSON = [{
  navigatorName: 'Nadine Thomas',
  sites: ['I15'],
  patients: 10,
},
{
  navigatorName: 'Garrett Chamberlain',
  sites: ['I19'],
  patients: 10,
},
{
  navigatorName: 'Jayde Nguyen',
  sites: ['I16'],
  patients: 10,
},
{
  navigatorName: 'Ruby Carnalla',
  sites: ['I16', 'I17', 'I18', 'I19'],
  patients: 10,
},
{
  navigatorName: 'Jasmine Johnson',
  sites: ['I14', 'I15', 'I16', 'I17', 'I18', 'I19', 'I22', 'I23'],
  patients: 10,
},
{
  navigatorName: 'Iliana De La Garza',
  sites: ['I14', 'I15', 'I16', 'I17', 'I18', 'I19', 'I22', 'I23'],
  patients: 0,
},
{
  navigatorName: 'Emilio Calderon',
  sites: [],
  patients: 0,
},
{
  navigatorName: 'L\'Sidni Helm',
  sites: [],
  patients: 0,
},
{
  navigatorName: 'Prithvi Narasimhan',
  sites: [],
  patients: 0,
},
{
  navigatorName: 'Rithesh Bhat',
  sites: [],
  patients: 0,
},
{
  navigatorName: 'Kirk Schloegel',
  sites: [],
  patients: 0,
},
{
  navigatorName: 'Tarren Evans',
  sites: [],
  patients: 0,
},
{
  navigatorName: 'Soham More',
  sites: [],
  patients: 0,
},
];

const App = () => {
  const [latestJSON, setLatestJSON] = useState(JSON.stringify(DEFAULT_JSON, null, 2));
  const parsedJSON: {
    status: 'failure',
    message: string,
  } | {
    status: 'success',
    data: t.TypeOf<typeof NavigatorJSON>,
  } = useMemo(() => {
    try {
      const jsonData =  JSON.parse(latestJSON)

      const decoded = NavigatorJSON.decode(jsonData); // Either<Errors, User>
      if (isLeft(decoded)) {
        throw Error(
          `Could not validate data: ${PathReporter.report(decoded).join("\n")}`
        );
      }
      return {
        status: 'success',
        data: decoded.right,
      };

    } catch (e) {
      return {
        status: 'failure',
        message: e.message,
      };
    }
  }, [latestJSON]);
  const [auth, setAuth] = useState<{
    username: string,
    password: string,
  }>({
    username: '', password: '',
  });
  const [results, setResults] = useState<{
    data: {
      failure: ResultT[],
      success: ResultT[],
      spanish: ResultT[],
      'no-assignment': ResultT[],
      'response already exists': ResultT[],
    },
    totalCount: number,
    currentCount: number,
  }>({
    data: {
      failure: [],
      'response already exists': [],
      'no-assignment': [],
      success: [],
      spanish: [],
    },
    totalCount: 0,
    currentCount: 0,
  });
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Card className="mx-auto text-small d-block border bg-light w-50 p-4 mb-3 mt-3">
        <textarea
          className="w-100 text-small"
          disabled={loading}
          value={latestJSON}
          rows={10}
          onChange={(e) => {
            setLatestJSON(e.target.value);
          }}
        />
        {parsedJSON.status === 'failure' && (
          <div className="text-danger">
            {parsedJSON.message}
          </div>
        )}
      </Card>
      <Card className="mx-auto d-block border bg-light w-50 p-4 mb-3 mt-3">
        <Form.Group>
          <Form.Label className="text-secondary font-weight-light">
            Email
          </Form.Label>
          <Form.Control
            type="text"
            value={auth.username}
            onChange={(e) => {
              setAuth({
                ...auth,
                username: e.target.value,
              });
            }}
          />
          <Form.Label className="text-secondary font-weight-light">
            Password
          </Form.Label>
          <Form.Control
            type="password"
            value={auth.password}
            onChange={(e) => {
              setAuth({
                ...auth,
                password: e.target.value,
              });
            }}
          />
          <Button
            variant="primary"
            className="mt-3 d-block mx-auto text-white"
            disabled={parsedJSON.status === 'failure' || loading}
            onClick={async () => {
              if (parsedJSON.status === 'failure') {
                return;
              }
              setLoading(true);
              const currentAssignments = await parseExistingServiceIntros(
                auth,
                parsedJSON.data,
                (totalCount: number) => {
                  setResults((curResults) => ({
                    ...curResults,
                    totalCount,
                  }));
                },
                (patientTvId: string, demographics: ServiceIntroDemographics, type: PatientResultTypes, data: string[]) => {
                  setResults((curResults) => ({
                    ...curResults,
                    currentCount: curResults.currentCount + 1,
                    data: {
                      ...curResults.data,
                      [type]: [
                        ...(curResults.data[type] || []),
                        {
                          patientTvId,
                          demographics,
                          data,
                        },
                      ],
                    },
                }));
                },
              );
              setLatestJSON(JSON.stringify(currentAssignments, null, 2));
              setLoading(false);
            }}
          >
            Update Service Intros
            {
              results.totalCount !== 0 && (
                <div>
                  {`(${results.currentCount}/${results.totalCount})`}
                </div>
              )
            }
          </Button>
        </Form.Group>
      </Card>
      {_.toPairs(results.data).map(([type, curResult]) => (
        <Card className={cx('mx-auto d-block border bg-light w-50 p-4 mb-3', {
          'border-success': type === 'success',
          'border-warning': type === 'spanish',
          'border-danger': type === 'failure',
        })}>
          <div className={cx('text-large text-capitalize text-center font-weight-bold mb-3', {
            'text-success': type === 'success',
            'text-warning': type === 'spanish',
            'text-danger': type === 'failure',
          })}>
            {type}
            <div className="text-small text-secondary font-weight-normal">
              {`Patients: ${curResult.length}`}
              {_.toPairs(_.groupBy(curResult, 'demographics.site')).map(([site, siteResults]) => (
                <div key={site}>
                  {`${site}: ${siteResults.length}`}
                </div>
              ))}
              {`by navigator name...`}
              {_.toPairs(_.groupBy(curResult, 'demographics.navigatorName')).map(([site, siteResults]) => (
                <div key={site}>
                  {`${site}: ${siteResults.length}`}
                </div>
              ))}
            </div>
          </div>
          {
            curResult.map(({ patientTvId, demographics, data }) => (
              <div key={patientTvId}>
                <Link
                  to={`https://app.symptohealth.com/provider/patients/${patientTvId}`}
                  className="text-decoration-none"
                  target="_blank"
                >
                  {demographics.firstName} {demographics.lastName}
                  <span className="text-small text-secondary pl-3">{` (MRN: ${demographics.mrn}) (Site: ${demographics.site})`}</span>
                </Link>
                <div className="text-secondary mb-3 text-small">
                  {data.map((d) => (d)).join(', ')}
                </div>
              </div>
            ))
          }
        </Card>
      ))}
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { BearerT } from '../utils/auth/utils';
import CampaignDropdown, { CampaignWithPatients } from './CampaignDropdown';

const CampaignSelect = ({
  deprecatedCampaigns,
  selectedCampaign,
  setSelectedCampaign,
  authData,
}: {
  deprecatedCampaigns: null | CampaignWithPatients[],
  selectedCampaign: null | CampaignWithPatients,
  setSelectedCampaign: (campaign: CampaignWithPatients) => void,
  authData: BearerT,
}) => {
  const [currentStatus, setCurrentStatus] = useState<string>(
    'Select a campaign',
  );
  return (
    <Card className="bg-light w-50 mx-auto p-4">
      <CampaignDropdown
        campaignData={deprecatedCampaigns}
        label="Select Deprecated Campaign to Unenroll Patients from"
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={(updatedCampaign) => {
          if (updatedCampaign.patientIds == null) {
            alert('Campaign does not have patients');
            return;
          }
          setSelectedCampaign(updatedCampaign);
          setCurrentStatus(
            `${updatedCampaign.patientIds.length} patients in ${updatedCampaign.name}`,
          );
        }}
        authData={authData}
      />
      <div className="text-small font-weight-bold text-primary text-center mt-3 mb-2">
        {currentStatus}
      </div>
    </Card>
  );
};

export default CampaignSelect;

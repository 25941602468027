import React, { useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { symptoReq,  generateAuthCode } from './utils/auth/utils';
import { generateAuthHeader, type BearerT } from './serviceIntros/utils';

import FileSet from './utils/FileSet';

const ReloadPatients = () => {
  const [encounterData, setEncounterData] = useState<
    null | { [key: string]: string; }[]
  >(null);
  const [refreshCount, setRefreshCount] = useState(null);
  const [auth, setAuth] = useState<{
    username: string,
    password: string,
  }>({
    username: '', password: '',
  });
  return (
    <div>
      <Card className="bg-light w-50 mx-auto d-block pb-3 mt-3">
        <div className="text-primary text-large text-center mb-2 mt-3">MRN List (from Kibana)</div>
        <FileSet
          requiredColumns={["MRN"]}
          placeholder="Drag 'n Drop or Upload Encounters Export Here (RMN Required)"
          updateData={(data) => {
            setEncounterData(data);
          }}
        />
      </Card>
      <Form.Label className="text-secondary font-weight-light">
        Email
      </Form.Label>
      <Form.Control
        type="text"
        value={auth.username}
        onChange={(e) => {
          setAuth({
            ...auth,
            username: e.target.value,
          });
        }}
      />
      <Form.Label className="text-secondary font-weight-light">
        Password
      </Form.Label>
      <Form.Control
        type="password"
        value={auth.password}
        onChange={(e) => {
          setAuth({
            ...auth,
            password: e.target.value,
          });
        }}
      />
      <Button
        className="mx-auto d-block text-white mt-3 mb-3"
        disabled={refreshCount != null}
        onClick={async () => {
          const authCode: BearerT = await generateAuthCode(auth);
          await encounterData.reduce(async (acc, { MRN: targetMRN }, index) => {
            await acc;
            const { patients } = await symptoReq(`/providers/patients/cache?searchTerm=${targetMRN}&limit=100&offset=0`, {
              "body": null,
              "method": "GET",
            }, generateAuthHeader(authCode));
            const matchingMRN = patients.find(({ mrn }) => mrn === targetMRN);
            if (matchingMRN != null) {
              await symptoReq(`/providers/patients/cache/${matchingMRN.tvid}`, {
                method: 'POST',
                body: JSON.stringify({}),
              }, generateAuthHeader(authCode));
            } else {
              swal(
                'Patient Not Found',
                `Patient with MRN ${targetMRN} not found`,
                'error',
              )
            }
            setRefreshCount(index + 1);
          }, Promise.resolve());
        }}
      >
        Update Patients
        {refreshCount != null &&
          <div className="text-small">
            {`${refreshCount} of ${encounterData.length} patients updated`}
          </div>
        }
      </Button>
    </div>
  );
}

export default ReloadPatients;

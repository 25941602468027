import cx from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { SymptoFields , SYMPTO_COLUMNS} from './generalData';

const CollapsibleRow = ({ text }: { text: string }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {text.length > 100 && open === false ? `${text.substring(0, 100)}...` : text}
      {text.length > 100 && (
        <Button
          variant="link"
          className="d-inline"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </>
  );
}

const LARGE_WIDTH_ROWS = [
  'Intro Message', 'ED Visit Summary', 'Encounter Details/Comments', 'Prescriptions',
]
const TableView = ({ parsedData, currentErrors, onEdit }: {
  parsedData: {
    headers: string[];
    data: {
      row: SymptoFields,
      id: string,
      index: number,
    }[],
  },
  currentErrors: {
    [key: number]: {
      row: number,
      msg: string,
    }[],
  },
  onEdit?: (index: number) => void,
}) => {
  const sortedHeaders = _.sortBy(parsedData.headers.map(header => ({
    header,
    index: SYMPTO_COLUMNS.indexOf(header) === -1 ? Number.POSITIVE_INFINITY : SYMPTO_COLUMNS.indexOf(header),
  })), 'index').map(({ header }) => header);

  return (
    <Table style={{ tableLayout: 'fixed', fontSize: '0.9em' }} className="p-3 border">
      <thead>
        <tr>
          {onEdit && (<th style={{ width: '5em' }} className="p-3">Edit</th>)}
          {sortedHeaders.map((row) => (
            <th style={{ width: LARGE_WIDTH_ROWS.includes(row) ? '50em' : '20em' }} className="p-3" key={row}>
              {row}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {parsedData.data.map(({ row, id, index }) => (
          <>
            {currentErrors[index] != null && (
              <>
                <tr style={{ width: '10em' }} key={`${id}-error-padding`}>
                    <td style={{ height: '5em' }} className="bg-light border-danger" colSpan={sortedHeaders.length}>
                    </td>
                </tr>
              </>
            )}
            <tr key={id}>
              {onEdit && (
                <td style={{ width: '5em' }} key={`${id}-edit`}>
                  <Button
                    variant="link"
                    className="d-inline text-decoration-none"
                    onClick={() => {
                      onEdit(index);
                    }}
                  >
                    ✏️
                  </Button>
                </td>
              )}
              {sortedHeaders.map((header) => (
                <td className={cx('p-3', { 'border-danger': currentErrors[index] != null })} style={{ width: LARGE_WIDTH_ROWS.includes(header) ? '50em' : '20em' }} key={header}>
                  <CollapsibleRow text={row[header]} />
                </td>
              ))}
            </tr>
            {currentErrors[index] != null && (
              <>
                  <tr key={`${id}-errors`}>
                    <td className={cx('text-danger mb-3', { 'border-danger': currentErrors[index] != null })}  colSpan={sortedHeaders.length}>
                      {currentErrors[index].map(({ msg }) => (
                        <div key={msg}>
                          {msg}
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr key={`${id}-error-padding`}>
                  <td style={{ height: '5em' }} className="text-danger mb-4 bg-light" colSpan={sortedHeaders.length}>

                  </td>
                </tr>
              </>
            )}
          </>
        ))}
      </tbody>
    </Table>
  );
}

TableView.defaultProps = {
  onEdit: null,
};

export default TableView;

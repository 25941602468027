import React, { useState } from 'react';
import swal from 'sweetalert';
import { Button, Card, Form } from 'react-bootstrap';
import { generateAuthCode, BearerT } from './utils';

const Auth = ({
  onSubmit,
  buttonNode,
  authDone,
}: {
  onSubmit: (auth: BearerT, creds: { username: string, password: string }) => Promise<void>,
  buttonNode: React.ReactNode,
  authDone?: boolean,
}) => {
  const [auth, setAuth] = useState<{
    username: string,
    password: string,
  }>({
    username: '', password: '',
  });
  const [loading, setLoading] = useState(false);
  return (
    <Card className="mx-auto d-block border bg-light w-50 p-4 my-3">
      {authDone && (
        <div className="text-center text-success display-6">
          Login Complete
        </div>
      )}
      {
        authDone !== true && (
          <Form.Group>
            <Form.Label className="text-secondary font-weight-light">
              Email
            </Form.Label>
            <Form.Control
              type="text"
              value={auth.username}
              onChange={(e) => {
                setAuth({
                  ...auth,
                  username: e.target.value,
                });
              }}
            />
            <Form.Label className="text-secondary font-weight-light">
              Password
            </Form.Label>
            <Form.Control
              type="password"
              value={auth.password}
              onChange={(e) => {
                setAuth({
                  ...auth,
                  password: e.target.value,
                });
              }}
            />
            <Button
              variant="primary"
              className="mt-3 d-block mx-auto text-white"
              disabled={
                loading ||
                auth.username === '' ||
                auth.password === ''
              }
              onClick={async () => {
                try {
                  setLoading(true);
                  const authData = await generateAuthCode({
                    username: auth.username,
                    password: auth.password,
                  });
                  await onSubmit(authData, {
                    username: auth.username,
                    password: auth.password,
                  });
                } catch (e) {
                  swal(
                    'Error',
                    e.message,
                    'error',
                  );
                } finally {
                  setLoading(false);
                }
              }}
            >
              {buttonNode}
            </Button>
          </Form.Group>
        )
      }
    </Card>
  );
}

Auth.defaultProps = {
  authDone: false,
};

export default Auth;

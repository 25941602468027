import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BackfillInstructions = () => (
  <Card className="bg-light p-3 w-50 d-block mx-auto shadow-none border mt-4 text-center">
    <div className="text-extra-large text-secondary">
      Instructions
    </div>
    <div className="font-weight-normal">
      Export from directly to CSV from Kibana
      <div className="font-weight-bold text-small">Must include 'id' and 'Patient Id' fields</div>
      <Link className="text-danger mb-3 font-weight-bold text-decoration-none" to="https://prod-sympto.kb.us-east-1.aws.found.io/app/r/s/l9fBY" target="_blank">
        <Button variant="link" className="d-block font-weight-bold text-decoration-none mx-auto">
          Example List
        </Button>
      </Link>
    </div>
  </Card>
);

export default BackfillInstructions;

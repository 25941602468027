import { Card } from 'react-bootstrap';
import React, { useState } from 'react';
import Auth from '../utils/auth/Auth';
import { BearerT } from '../utils/auth/utils';
import BackfillInstructions from './BackfillInstructions';
import FileSet from '../utils/FileSet';
import StatusIgnore from './StatusIgnore';
import JSONEditor, { DEFAULT_JSON, QuestionData } from './JSONEditor';
import UploadTrigger from './UploadTrigger';


const MessageBackfill = () => {
  const [authData, setAuthData] = useState<null | BearerT>(null);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [encounterList, setEncounterList] = useState<null |  {
    [key: string]: string;
  }[]>(null);
  const [jsonValue, setJsonValue] = useState<QuestionData>(DEFAULT_JSON);
  return (
    <div className="pb-5">
      <BackfillInstructions />
      <Auth
        buttonNode="Login"
        authDone={authData != null}
        onSubmit={async (authData) => {
          setAuthData(authData);
        }}
      />
      <Card className="bg-light w-50 mx-auto d-block pb-3 mt-3">
        <div className="text-primary text-large text-center mb-2 mt-3">Kibana Data</div>
        <FileSet
          requiredColumns={["id", "Patient Id"]}
          placeholder="Drag 'n Drop or Upload Kibana Export Here"
          updateData={(data) => {
            setEncounterList(data);
          }}
        />
      </Card>
      <StatusIgnore
        authData={authData}
        setStatuses={setStatuses}
        statuses={statuses}
      />
      <JSONEditor
        jsonValue={jsonValue}
        setJsonValue={setJsonValue}
      />
      {authData != null && encounterList != null && (
        <UploadTrigger
          authData={authData}
          encounterList={encounterList}
          jsonValue={jsonValue}
          statuses={statuses}
        />
      )}

    </div>
  )
};

export default MessageBackfill;

import swal from 'sweetalert';
import { ChatModel } from './modelList';

const CLIENT_URL = 'https://aichatapi.symptohealth.com';

export const getAuthToken = async (username: string, password: string): Promise<string> => {
  try {
    const response = await fetch(`${CLIENT_URL}/auth?username=${username}&password=${password}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to authenticate');
    }

    const data = await response.json();
    return data.authorizationToken;
  } catch (error) {
    swal('Error', `Failed to authenticate: ${error}`, 'error');
    throw error;
  }
};

const getPromptResponse = async (
  token: string,
  systemPrompt: string,
  messages: { message: string; from: 'user' | 'model'; id: string }[],
  model: ChatModel
): Promise<string> => {
  const response = await fetch(`${CLIENT_URL}/instruments/prompt/helper`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      systemPrompt,
      model,
      messages,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to get prompt response');
  }

  const reader = response.body?.getReader();
  const decoder = new TextDecoder();
  let result = '';

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    result += decoder.decode(value, { stream: true });
  }

  return result;
};

export const runLangChain = async ({
  response,
  prompt,
  aiAuthToken,
  model,
}: {
  response: Record<string, any>;
  prompt: string;
  aiAuthToken: string;
  model: ChatModel;
}): Promise<string> => {
  try {
    const messages = [
      {
        message: `${prompt}\n\n${JSON.stringify(response)}`,
        from: 'user' as 'user', // Explicitly set the type
        id: '1',
      },
    ];
    const result = await getPromptResponse(aiAuthToken, 'Your system prompt here', messages, model);
    console.log('AI Response:', result);
    return result;
  } catch (error) {
    console.error('Error running AI:', error);
    swal('Error', `Failed to run AI: ${error}`, 'error');
  }
};

export const continueLangchainOutput = async ({
  response,
  prompt,
  aiAuthToken,
  model,
  previousOutput,
}: {
  response: Record<string, any>;
  prompt: string;
  aiAuthToken: string;
  model: ChatModel;
  previousOutput: string;
}): Promise<string> => {
  try {
    const messages = [
      {
        message: `${prompt}\n\n${JSON.stringify(response)}`,
        from: 'user' as 'user', // Explicitly set the type
        id: '1',
      },
      {
        message: previousOutput,
        from: 'model' as 'model', // Explicitly set the type
        id: '2',
      },
      {
        message: 'Continue',
        from: 'user' as 'user', // Explicitly set the type
        id: '3',
      }
    ];
    const result = await getPromptResponse(aiAuthToken, 'Your system prompt here', messages, model);
    console.log('AI Response:', result);
    return result;
  } catch (error) {
    console.error('Error running AI:', error);
    swal('Error', `Failed to run AI: ${error}`, 'error');
  }
};

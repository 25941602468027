import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import fileDownload from 'js-file-download';
import { json2csv } from 'json-2-csv';
import { bulkUploadParse } from './bulkUploadParse';
import FileUploadJSON from '../utils/FileUploadJSON';
import { SymptoFields } from './generalData';
import { calculateHeaders } from '../utils/csvUtils';
import TableView from './TableView';
import EditModal from './EditModal';

const JSONEditor = () => {
  const [parsedData, setParsedData] = useState<
    null | {
      headers: string[];
      data: {
        row: SymptoFields,
        id: string,
        index: number,
      }[],
    }
  >(null);
  const [currentErrors, setErrors] = useState<{
    [key: number]: {
      row: number,
      msg: string,
    }[],
  }>({});
  const [fileName, setFileName] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [hasUpdates, setHasUpdates] = useState(false);

  useEffect(() => {
    try {
      if (uploadData == null) {
        return;
      }
      const { errors, data } = bulkUploadParse(uploadData);
      const headers = calculateHeaders(data);
      setParsedData({
        headers,
        data: data.map((row, index) => ({ row, index, id: uuidv4() })),
      });
      setErrors(_.groupBy(errors, 'row'));
    } catch (e) {
      swal('Error', e.message, 'error')
    }
  }, [uploadData])
  return (
    <div>
      {editIndex != null && uploadData != null && (
        <EditModal
          data={uploadData[editIndex]}
          onUpdate={(newData) => {
            const newUploadData = [...uploadData];
            newUploadData[editIndex] = newData;
            setUploadData(newUploadData);
            setEditIndex(null);
            setHasUpdates(true);
          }}
          onCancel={() => {
            setEditIndex(null);
          }}
        />
      )}
      {fileName == null && (
        <FileUploadJSON
          placeholder="Drag 'n Drop Raw JSON"
          onUpload={(latestUploadData) => {
            try {
              setUploadData(latestUploadData);
              return true;
            } catch (e) {
              swal('Error', e.message, 'error');
              return false;
            }
          }}
          fileName={fileName}
          setFileName={setFileName}
        />
      )}
      {fileName != null && (
        <div className="text-center mt-4 text-secondary">
          <div>
            <span className="text-dark pr-2">File Name: </span>
            {fileName}
          </div>
          <Button variant="link" onClick={() => { window.location.reload(); }}>
            New File
          </Button>
        </div>
      )}
      {hasUpdates && (
        <Button
          onClick={() => {
            const str = JSON.stringify(uploadData);
            const bytes = new TextEncoder().encode(str);
            const blob = new Blob([bytes], {
                type: "application/json;charset=utf-8"
            });

            fileDownload(
              blob,
              `sympto-failed-updates-${new Date().toISOString()}.json`,
            );

          }}
          variant="primary"
          className="text-white d-block mx-auto my-3 w-50 font-weight-bold"
        >
          Download Updated JSON
        </Button>
      )}
      <Button
        onClick={async () => {
          const noNewLines = uploadData.map((row) => (_.fromPairs(_.toPairs(row).map(([key, value]) => [
            key,
            // remove all new lines of all types
            String(value).replace(/\n/g, '.').replace(/\r/g, '.').replace(/\r\n/g, '.').replace(/,/g, ';'),
          ]))))
          const csv = await json2csv(noNewLines, {
            checkSchemaDifferences: true,
            delimiter: {
              field: ',',
            },
          });
          const bytes = new TextEncoder().encode(csv);
          const blob = new Blob([bytes], {
              type: "text/csv;charset=utf-8"
          });

          fileDownload(
            blob,
            `sympto-updates-${new Date().toISOString()}.csv`,
          );
        }}
        variant="primary"
        className="text-white d-block mx-auto my-3 w-50 font-weight-bold"
      >
        Download Excel
      </Button>
      {parsedData && (
        <TableView
          parsedData={parsedData}
          currentErrors={currentErrors}
          onEdit={(rowIndex) => {
            setEditIndex(rowIndex);
          }}
        />
      )}
    </div>
  );
}

export default JSONEditor;

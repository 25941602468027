import { Button, Card, ProgressBar } from 'react-bootstrap';
import _ from 'lodash';
import React, { useState } from 'react';
import Select from 'react-select';
import {
  CampaignT,
  getCampaigns,
} from './manageCampaigns';
import Auth from '../utils/auth/Auth';
import { BearerT } from '../utils/auth/utils';
import CampaignSelect from './CampaignSelect';
import ReplacementCampaignSelect from './ReplacementCampaignSelect';
import { CampaignWithPatients } from './CampaignDropdown';
import { bulkFixCampaigns } from './manageCampaigns';

const CampaignManagement = () => {
  const [authData, setAuthData] = useState<null | BearerT>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [maxNumberToUnenroll, setMaxNumberToUnenroll] = useState<number>(1);
  const [deprecatedCampaigns, setDeprecatedCampaigns] = useState<null | CampaignWithPatients[]>(null);
  const [liveCampaigns, setLiveCampaigns] = useState<null | CampaignT[]>(null);
  const [currentCampaign, setCurrentCampaign] = useState<CampaignWithPatients | null>(null);
  const [replacementCampaign, setReplacementCampaign] = useState<CampaignWithPatients | null>(null);
  const progressPercentage = maxNumberToUnenroll
    ? (progress / maxNumberToUnenroll) * 100
    : 0;

  const [authProgress, setAuthProgress] = useState<{
    numCampaigns: number;
    numProcessed: number
  } | null>(null);

  return (
    <div>
      <Auth
        buttonNode={(
          <div>
            Login
            <div className="text-small">
              {authProgress != null && (
                `Loading Campaign ${authProgress.numProcessed} of ${authProgress.numCampaigns}`
              )}
            </div>
          </div>
        )}
        authDone={authData != null}
        onSubmit={async (authData) => {
          // once auth data is fetched, first fetch a list of campaigns
          const data = await getCampaigns({
            authData,
            onProgress: (numProcessed, numCampaigns) => {
              setAuthProgress({
                numProcessed,
                numCampaigns,
              });
            }
          });
          if (data.deprecatedCampaigns == null || data.deprecatedCampaigns.length === 0) {
            return;
          }
          setDeprecatedCampaigns(data.deprecatedCampaigns);
          setLiveCampaigns(data.liveCampaigns);
          setAuthData(authData);
        }}
      />
      {authData != null && (
        <CampaignSelect
          deprecatedCampaigns={deprecatedCampaigns}
          selectedCampaign={
            currentCampaign
          }
          authData={authData}
          setSelectedCampaign={(campaign) => {
            setCurrentCampaign(campaign);
          }}
        />
      )}
      {
        currentCampaign != null && currentCampaign.patientIds != null && (
          <ReplacementCampaignSelect
            replacementCampaign={replacementCampaign}
            setReplacementCampaign={setReplacementCampaign}
            activeCampaigns={liveCampaigns}
            authData={authData}
          />
        )
      }
      <Card className="mx-auto border bg-light w-50 p-4 mb-3 mt-4">
        <div className="pb-3 text-center">
          <div className="font-weight-bold me-1 mb-3">
            Depricated Campaigns with Patients
          </div>
          <div className="d-flex  mb-3">
            <div>
              <div
                className="me-2 text-secondary text-small"
              >
                # of Patients to Unenroll
              </div>
              <Select
                className="text-small"
                placeholder="Search for Tag"
                isMulti={false}
                options={[
                  1, 10, 100, 1000, 5000, 10000, 20000
                ].map((value) => ({
                  value,
                  label: `First ${value} patients`,
                }))}
                value={{
                  value: maxNumberToUnenroll,
                  label: `First ${maxNumberToUnenroll} patients`,
                }}
                onChange={async (data) => {
                  setMaxNumberToUnenroll(data.value);
                }}
              />
            </div>
            <Button
              className={loading ? 'bg-warning' : 'bg-primary'}
              onClick={async () => {
                setLoading(true);
                if (currentCampaign.patientIds == null) {
                  alert('Campaign does not have patients');
                  return;
                }
                await bulkFixCampaigns({
                  authData,
                  existingPatientCampaignId: currentCampaign.campaignId,
                  patientTvIds: _.slice(currentCampaign.patientIds, 0, maxNumberToUnenroll),
                  newPatientCampaignId: replacementCampaign?.campaignId,
                  onProgress: () => {
                    setProgress((prevProgress) => prevProgress + 1);
                  }
                });
                setLoading(false);
              }}
              variant="primary"
              disabled={loading}
            >
              {loading
                ? 'Loading...'
                : 'Unenroll all from the selected campaigns'}
            </Button>
          </div>
          <div className="mt-3">
            <ProgressBar
              now={progressPercentage}
              label={`${Math.round(progressPercentage)}%`}
              className="mt-1"
            />
            <div className="text-secondary font-weight-bold mx-auto text-small mt-2">
              {`${progress} of ${maxNumberToUnenroll} patients unenrolled`}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CampaignManagement;

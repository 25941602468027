import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import swal from 'sweetalert';
import { calculateHeaders } from './csvUtils';
import FileUpload from './FileUpload';

type Props = {
  updateData: (data: null | { [key: string]: string; }[]) => void,
  requiredColumns: string[],
  placeholder: string,
};

const FileSet = ({
  updateData, requiredColumns, placeholder,
}: Props) => {
  const [fileName, setFileName] = useState(null);
  return (
    <div>
      {fileName == null && (
        <FileUpload
          placeholder={placeholder}
          onUpload={(latestUploadData) => {
            try {
              const headers = calculateHeaders(latestUploadData);
              requiredColumns.forEach((column) => {
                if (!headers.includes(column)) {
                  throw new Error(`${column} required in upload`);
                }
              });
              updateData(latestUploadData);
              return true;
            } catch (e) {
              swal('Error', e.message, 'error')
              return false;
            }
          }}
          allowExcel
          targetSheetNames={["DirectorySpreadsheet_Export"]}
          fileName={fileName}
          setFileName={setFileName}
        />
      )}
      {fileName != null && (
        <div className="text-center mt-4 text-secondary">
          <div>
            <span className="text-dark pr-2">File Name: </span>
            {fileName}
          </div>
          <Button variant="link" onClick={() => { updateData(null); setFileName(null); }}>
            New File
          </Button>
        </div>
      )}
    </div>
  );
}

export default FileSet;

import React, { useCallback, useState } from 'react';
import { ChatModel } from './modelList';
import { Card, Button, Form, Spinner } from 'react-bootstrap';
import swal from 'sweetalert';
import pLimit from 'p-limit';
import FileSet from '../utils/FileSet';
import { BearerT, symptoReq, generateAuthHeader } from '../utils/auth/utils';
import Auth from '../utils/auth/Auth';
import useLocalStorage from 'use-local-storage';
import { runLangChain, getAuthToken } from './langchain';
import Select from 'react-select';
import modelList from './modelList';
import LangchainRun from './LangchainRun';

const limit = pLimit(3);

const AIRun = () => {
  const [auth, setAuth] = useState<undefined | {
    username: string;
    password: string;
    bearerToken: BearerT;
    aiAuthToken: string;
  } | null>(null);
  const [fileData, setFileData] = useState(null);
  const [prompt, setPrompt] = useLocalStorage('prompt', '');
  const [loading, setLoading] = useState(null);
  const [selectedModel, setSelectedModel] = useLocalStorage<ChatModel>('selectedModel', null);
  const [responses, setResponses] = useLocalStorage<Array<{
    analyticsOutput: Record<string, any>;
    langchainOutput: string;
    id: string;
  }>>('responsesAI', []);

  const runAI = useCallback(async () => {
    if (!fileData || !auth) return;

    setLoading({
      progress: 0,
      total: fileData.length,
    });

    await Promise.all(fileData.map((data) => limit(async () => {
      // skip if already processed
      if (responses.find(response => response.id === data.id)) return;
      try {
        const res = await symptoReq(
          `/providers/responses/${data.id}/analytics`,
          {
            method: 'GET',
          },
          generateAuthHeader(auth.bearerToken),
          true,
        );

        // Call LangChain function with the response and Anthropic API key
        const langchainOutput = await runLangChain({
          response: res,
          prompt,
          aiAuthToken: auth.aiAuthToken,
          model: selectedModel,
        });

        setResponses((prevResponses) => {
          const updatedResponses = [...prevResponses, { analyticsOutput: res, langchainOutput, id: data.id }];
          return updatedResponses;
        });

      } catch (e) {
        console.error(e);
        swal("Error", `An error occurred while processing the request. ${e}`, "error");
      } finally {
        setLoading((prev) => {
          if (prev) {
            return {
              ...prev,
              progress: prev.progress + 1,
            };
          }
          return {
            progress: 1,
            total: fileData.length,
          };
        });
      }
    })));

    setLoading(null);
  }, [fileData, auth, responses, prompt, selectedModel, setResponses]);

  const clearResponses = () => {
    setResponses([]);
  };

  const downloadAnalyticsOutput = () => {
    const analyticsData = responses.map(response => response.langchainOutput).join('\n');
    const blob = new Blob([analyticsData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'analytics_output.txt';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Auth
        buttonNode="Login"
        authDone={auth != null}
        onSubmit={async (authData, creds) => {
          setAuth({
            aiAuthToken: null,
            bearerToken: authData,
            ...creds,
          });
          const aiAuthToken = await getAuthToken(creds.username, creds.password);
          setAuth((prev) => ({
            ...prev,
            aiAuthToken,
          }));
        }}
      />
      <Card className="bg-light w-50 mx-auto d-block pb-3 mt-3">
        <Card.Body>
          <Card.Title className="text-primary text-center mb-2 mt-3">Upload Data</Card.Title>
          <FileSet
            requiredColumns={["id"]}
            placeholder="Drag 'n Drop or Upload File Here"
            updateData={(data) => {
              setFileData(data);
            }}
          />
        </Card.Body>
      </Card>
      <Card className="bg-light w-50 mx-auto d-block pb-3 mt-3">
        <Card.Body>
          <Form.Group>
            <Form.Label>Enter your prompt here</Form.Label>
            <Form.Control
              as="textarea"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Enter your prompt here"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Select Model</Form.Label>
            <Select
              options={modelList.map(model => ({ value: model, label: model }))}
              value={{
                value: selectedModel,
                label: selectedModel,
              }}
              onChange={(option) => setSelectedModel(option.value)}
              placeholder="Select a model"
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Button
        onClick={runAI}
        className="mx-auto mt-3 d-block text-white"
        disabled={fileData == null || auth == null || loading != null}
      >
        Run AI
        {loading != null && (
          <div className="text-small text-white">
            <Spinner animation="border" size="sm" className="me-2" />
            {loading.progress}/{loading.total}
          </div>
        )}
      </Button>
      {responses.length > 0 && auth != null && (
        <Button
          onClick={clearResponses}
          className="mx-auto mt-3 d-block text-white"
          variant="danger"
        >
          Clear Responses
        </Button>
      )}
      {responses.length > 0 && auth != null && (
        <Button
          onClick={downloadAnalyticsOutput}
          className="mx-auto mt-3 d-block text-white"
          variant="primary"
        >
          Download Analytics Output
        </Button>
      )}
      {responses.length > 0 && auth != null && responses.map((response, index) => (
        <LangchainRun
          key={index}
          runIndex={index}
          analyticsOutput={response.analyticsOutput}
          prompt={prompt}
          updateResponse={(updatedResponse) => {
            setResponses((prev) => {
              const updatedResponses = [...prev];
              updatedResponses[index].langchainOutput = updatedResponse;
              return updatedResponses;
            });
          }}
          selectedModel={selectedModel}
          langchainOutput={response.langchainOutput}
          aiAuthToken={auth.aiAuthToken}
        />
      ))}
    </div>
  );
};

export default AIRun;

export const NEW_PATIENT = `NEW PATIENT - New Patient Created w/ New Encounter. No matching patient with MRN`;
export const DUP_PATIENT_PHONE = `NEW PATIENT - New Patient Created w/ New Encounter (With Different System Phone Number)`;
export const ENROLL_IN_ENCOUNTER = `EXISTING PATIENT / NEW ENCOUNTER - New Encounter Created for Existing Patient`;

export const LAST_ENCOUNTER_30 = `SKIPPED - Skipped Service Intro Patient. Last Encounter less than 30 days ago`;
export const ALREADY_ASSIGNED = `SKIPPED - Skipped Admin or Referral Patient. Encounter with same encounter # / MRN already exists and is assigned`;

export const MANUALLY_UPDATE = `FAILURE - Encounter found for patient with the same MRN and same encounter # with no navigator name. Manually update navigator name in encounter`;
export const MANUALLY_UPDATE_DIFFERENT_NAV = `FAILURE - Encounter found for patient with the same MRN and same encounter # with DIFFERENT navigator name. Manually update navigator name in encounter`;
export const PATIENT_FAILED = `FAILURE - Patient failed to update`;
export const MULTIPLE_ENCOUNTERS = `FAILURE - Multiple encounters already associated with this patient with the same MRN and same encounter #.`;

export const UPDATING_PATIENT = `Intermediate Step - Attempting to be update patient`;
export const SERVICE_INTRO_PATIENT = `Intermediate Step - Patient recognized as Service Intro Patient`;
export const NO_VALID_ENCOUNTER = `Intermediate Step - Attempting to create new encounter for existing patient`;
export const NO_PHONE = `Intermediate Step -  No phone number. patient will be assigned dummy @symptohealth.com email address`;
export const WARNING_PH_NUMBER_INVALID = `Intermediate Step -  Warning: Patient has invalid phone number`;
export const ATTEMPTING_NEW_PATIENT = `Intermediate Step - Attempting to create new patient`;
export const ATTEMPTING_DUP_PH = `Intermediate Step - Patient already exists with ph number. Attempting to create dup patient with different system phone number`;

export const SKIPPING_NON_MERCY = `SKIPPED - Not Mercy Roseburg. Skipping...`;
export const NOT_DISCHARGED = `SKIPPED - Not Discharged. Skipping...`;
export const NOT_EMERGENCY = 'SKIPPED - Not emergency. Skipping...';

export const getLogType = (logString: string): 'Intermediate' | 'Failure' | 'Skipped' | 'Success' => {
  if (logString.startsWith('Intermediate Step - ')) {
    return 'Intermediate';
  }
  if (logString.startsWith(`FAILURE`)) {
    return 'Failure';
  }
  if (logString.startsWith(`SKIPPED`)) {
    return 'Skipped';
  }
  if (logString.startsWith('NEW PATIENT') || logString.startsWith('EXISTING PATIENT / NEW ENCOUNTER')) {
    return 'Success';
  }
  // defaults to failure
  return 'Failure';
}

export const LOG_MESSAGES = [
  NEW_PATIENT,
  DUP_PATIENT_PHONE,
  ENROLL_IN_ENCOUNTER,
  SKIPPING_NON_MERCY,
  NOT_DISCHARGED,
  NOT_EMERGENCY,

  LAST_ENCOUNTER_30,
  ALREADY_ASSIGNED,

  MANUALLY_UPDATE,
  MANUALLY_UPDATE_DIFFERENT_NAV,
  PATIENT_FAILED,
  MULTIPLE_ENCOUNTERS,

  UPDATING_PATIENT,
  SERVICE_INTRO_PATIENT,
  NO_VALID_ENCOUNTER,
  NO_PHONE,
  WARNING_PH_NUMBER_INVALID,
  ATTEMPTING_NEW_PATIENT,
  ATTEMPTING_DUP_PH,
];

import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import { LOG_MESSAGES, getLogType } from '../bulkUploads/logs';
import { SYMPTO_COLUMNS } from './bulkUploadParse';

const ErrorLogs = ({ parsedData, errors }: {
  errors: {
    // row value maps to index value in parsed data
    row: number,
    msg: string,
  }[],
  parsedData: {
    row: Record<SYMPTO_COLUMNS, string>,
    index: number,
  }[],
}) => {
  return (
    <div className="ErrorLogs w-50 mx-auto">
      {LOG_MESSAGES.map((msg) => {
        const rowsWithError = _.uniq(
          errors
            .filter(({ msg: errorMsg }) => errorMsg.includes(msg))
            .map(({ row }) => row));
        const patientsWithError = parsedData.filter(({ index }) => (
          rowsWithError.includes(index)
        ));
        const logType = getLogType(msg);
        if (patientsWithError.length === 0) {
          return (<></>)
        }

        return (
          <Card className="bg-light p-3 mb-4 shadow-none" key={msg}>
            <div className={cx({
              'text-secondary': logType === 'Intermediate',
              'text-success': logType === 'Success',
              'text-danger': logType === 'Failure',
              'text-dark': logType === 'Skipped',
            })}>
              {`${patientsWithError.length} matches`}
            </div>
            <div className="text-small text-secondary">
              {`for ${msg}`}
            </div>
            {
              logType !== 'Intermediate' && (
                <div className="text-small mt-2">
                  <div>
                    {patientsWithError.map(({ row }) => (
                      <div key={row['Identification']}>
                        {row['Identification']}
                      </div>
                    ))}
                  </div>
                </div>
              )
            }
            <Button
              onClick={() => {
                const str = JSON.stringify(patientsWithError.map(({ row }) => row));
                const bytes = new TextEncoder().encode(str);
                const blob = new Blob([bytes], {
                    type: "application/json;charset=utf-8"
                });

                fileDownload(
                  blob,
                  `sympto-parsed-data-${new Date().toISOString()}.json`,
                );
              }}
              variant="primary"
              className="text-white"
            >
              Download JSON
            </Button>
          </Card>
        )
      })}
    </div>
  );
}

export default ErrorLogs;

import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const EditModal = ({
  data,
  onCancel,
  onUpdate,
}: {
  data: Record<string, string>,
  onCancel: () => void,
  onUpdate: (newData: Record<string, string>) => void,
}) => {
  const [currentData, setCurrentData] = useState<Record<string, string>>(data);
  useEffect(() => {
    setCurrentData(data);
  }, [data]);
  return (
    <Modal
      show={true}
      onHide={() => {
        onCancel();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Row</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {
          Object.keys(currentData).map((key) => (
            <div key={key} className="mb-3">
              <div className="text-small text-secondary mb-1">{key}</div>
              <Form.Control
                type="text"
                value={currentData[key]}
                onChange={(e) => {
                  setCurrentData({
                    ...currentData,
                    [key]: e.target.value,
                  });
                }}
              />
            </div>
          ))
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onUpdate(currentData);
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal;

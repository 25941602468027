import _ from 'lodash';

const REQUIRED_COLUMNS = [
  'First Name', 'Last Name', 'DOB', 'MRN', 'Patient Address', 'Patient Phone', 'Race', 'Ethnicity', 'Preferred/Primary Language', 'Admit Date', 'Discharge Date', 'Discharge Event', 'Transfer Event', 'Account Number', 'Discharge Disposition', 'Major Class', 'Visit Type', 'Attending Physician', 'Visit Facility', 'Location', 'Payers', 'Chief Complaint', 'Diagnoses', 'PCP', 'ED Visit Count 6 Mo', 'IP Visit Count 6 Mo',
];

export type SYMPTO_COLUMNS = 'First Name' | 'Last Name' | 'DOB' | 'MRN' | 'Patient Address' | 'Patient Phone' | 'Race' | 'Ethnicity' | 'Preferred/Primary Language' | 'Admit Date' | 'Discharge Date' | 'Discharge Event' | 'Transfer Event' | 'Account Number' | 'Discharge Disposition' | 'Major Class' | 'Visit Type' | 'Attending Physician' | 'Visit Facility' | 'Location' | 'Payers' | 'Chief Complaint' | 'Diagnoses' | 'PCP' | 'ED Visit Count 6 Mo' | 'IP Visit Count 6 Mo';

export type PatientData = {
  [key in SYMPTO_COLUMNS]: string;
};

export const bulkUploadParse = (data: {
  [key: string]: string,
}[]): {
  data: Record<SYMPTO_COLUMNS, string>[],
} => {
  if (data.some((row) => (
    REQUIRED_COLUMNS.some((col) => (row[col] == null))
  ))) {
    const missingColumns = _.uniq(_.compact(_.flatten(data.map((row) => (
      REQUIRED_COLUMNS.map((col) => (row[col] == null ? col : null))
    )))));
    throw new Error(`Invalid upload. Missing ${missingColumns.join(', ')} for some records`);
  }
  return {
    data: data.map((row) => (row as Record<SYMPTO_COLUMNS, string>)),
  };
};

import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AnalyticsInstructions = () => (
  <Card className="bg-light p-3 w-50 d-block mx-auto shadow-none border mt-4">
    <div className="text-extra-large font-weight-light text-center">
      Instructions
    </div>
    <ul className="mt-3 font-weight-light">
      <div className="text-center">
        Export from directly to CSV
        <Link className="text-danger mb-3 font-weight-bold text-decoration-none" to="https://prod-sympto.kb.us-east-1.aws.found.io/s/navigators/app/r/s/KRJIR" target="_blank">
          <Button className="d-block text-white mx-auto mt-3 mb-2">
            Patients that need to be reloaded
          </Button>
        </Link>
      </div>
    </ul>
  </Card>
);

export default AnalyticsInstructions;

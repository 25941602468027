import { Card, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { QuestionData } from './JSONEditor';
import { symptoReq } from '../utils/auth/utils';
import { generateAuthHeader, type BearerT } from '../serviceIntros/utils';

export const FOLLOWUP_KEY = 'Followup Message';

const MessageBackfill = ({
  authData,
  encounterList,
  jsonValue,
  statuses,
}: {
  authData: BearerT,
  statuses: string[],
  encounterList: {
    [key: string]: string;
  }[],
  jsonValue: QuestionData,
}) => {
  const [patientResults, setPatientResults] = useState<{
    tvid: string,
    firstName: string,
    lastName: string,
    ignoredStatus: { status: 'ok' } | {
      status: 'error',
      message: string,
    },
    messageStatus: { status: 'ok' } | {
      status: 'error',
      message: string,
    },
    encounterStatus: { status: 'ok' } | {
      status: 'error',
      message: string,
    },
  }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <>
      <Button
        className="d-block mx-auto mt-3 text-white"
        onClick={async () => {
          setLoading(true);
          await encounterList.reduce(async (prev, { id: patientSurveyId, "Patient Id": patientId }) => {
            await prev;
            // first check if patient has status
            const patientData: {
              statuses: {
                statusName: string,
              }[],
              tvid: string,
              firstName: string,
              lastName: string,
            } = await symptoReq(
              `/providers/patients/cache/${patientId}`,
              {
                method: 'GET',
              },
              generateAuthHeader(authData),
              true,
            );
            const resultsData = {
              tvid: patientData.tvid,
              firstName: patientData.firstName,
              lastName: patientData.lastName,
            };
            if (patientData.statuses.some(({ statusName }) => (statuses.includes(statusName)))) {
              setPatientResults((curResults) => ([
                ...curResults,
                {
                  ...resultsData,
                  ignoredStatus: {
                    status: 'error',
                    message: `Patient has status: ${patientData.statuses.map(({ statusName }) => (statusName)).join(', ')}`
                  },
                  messageStatus: {
                    status: 'error',
                    message: 'Skipped',
                  },
                  encounterStatus: {
                    status: 'error',
                    message: 'Skipped',
                  },
                },
              ]));
              return;
            }
            try {
              await symptoReq(
                `/providers/${patientSurveyId}/encounters/response`,
                {
                  method: 'POST',
                  body: JSON.stringify(
                    jsonValue.filter(({ questionTitle }) => (questionTitle !== FOLLOWUP_KEY)),
                  ),
                },
                generateAuthHeader(authData),
                true,
              );
            } catch (e) {
              setPatientResults((curResults) => ([
                ...curResults,
                {
                  ...resultsData,
                  ignoredStatus: { status: 'ok'  },
                  encounterStatus: {
                    status: 'error',
                    message: e.message,
                  },
                  messageStatus: {
                    status: 'error',
                    message: 'Skipped',
                  },

                },
              ]));
              return;
            }
            try {
              const followupMessage = jsonValue.find(({ questionTitle }) => (questionTitle === FOLLOWUP_KEY));
              if (followupMessage != null) {
                await symptoReq(
                  `/provider/messaging/${patientId}`,
                  {
                    method: 'POST',
                    body: JSON.stringify({
                      includeSignature: false,
                      message: followupMessage.value

                    }),
                  },
                  generateAuthHeader(authData),
                  true,
                );
              }
              setPatientResults((curResults) => ([
                ...curResults,
                {
                  ...resultsData,
                  ignoredStatus: { status: 'ok'  },
                  encounterStatus: { status: 'ok' },
                  messageStatus: { status: 'ok' },
                },
              ]));
            } catch (e) {
              setPatientResults((curResults) => ([
                ...curResults,
                {
                  ...resultsData,
                  ignoredStatus: { status: 'ok'  },
                  encounterStatus: { status: 'ok' },
                  messageStatus: {
                    status: 'error',
                    message: e.message,
                  },
                },
              ]));
              return;
            }
          }, Promise.resolve());
          setLoading(false);
        }}
        disabled={loading}
      >
        Upload
        {loading != null && (
          <div className="text-center text-small">
            {`Uploaded ${patientResults.length} of ${encounterList.length} patients`}
          </div>
        )}
      </Button>
      <Card className="bg-light w-50 mx-auto d-block pb-3 mt-3">
        {
          patientResults.map(({ tvid, firstName, lastName, ignoredStatus, messageStatus, encounterStatus }) => (
            <div className="border-bottom px-3 py-3" key={tvid}>
              <a
                href={`https://app.symptohealth.com/provider/patients/${tvid}`}
                target="_blank"
                rel="noreferrer"
                className="text-decoration-none"
              >
                <div className="text-large">
                  {firstName} {lastName}
                </div>
                <div className="text-small">
                  {`ID: ${tvid}`}
                </div>
              </a>
              {[{
                title: 'Ignore Status',
                value: ignoredStatus,
              }, {
                title: 'Message Status',
                value: messageStatus,
              }, {
                title: 'Encounter Status',
                value: encounterStatus,
              }].map(({ title, value }) => (
                <div className="d-flex text-small my-1">
                  <div className="font-weight-bold pe-2">{title}: </div>
                  <div>
                    {value.status === 'ok' ? (
                      <span className="text-success">
                        ✅
                      </span>
                    ) : (
                      <span className="text-danger">
                        ❌ {value.message}
                      </span>
                    )}
                  </div>
                </div>
              ))}

            </div>
          ))
        }
      </Card>
    </>
  );
};

export default MessageBackfill;
